.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.filter-section {
  /* padding: 1rem 0rem !important; */
  display: flex;
  justify-content: space-between;
  /* gap: 12px 12px; */
  flex-flow: row nowrap;
  /* float: right; */
}

.search-section {
  display: flex;
  /* gap: 12px 12px; */
  flex-flow: row nowrap;
}

.search-section div, .search-section .ui.button, .ui.button.add-new-order-button {
  /* margin: 12px 0px !important; */
  margin-top: 12px;
  margin-bottom: 12px;
}

.date-range-container {
  position: absolute;
  z-index: 9999;
  top: 125px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  
  .filter-section, .ag-theme-alpine, .navbar { 
    width: calc(100% - 16px);
    margin: auto;
  }

  .filter-section, .search-section, .navbar {
    flex-flow: column nowrap;
  }

  .ag-theme-alpine {
    height: 480px;
  }

  .date-range-container {
    top: 210px;
  }

  .ui.button.add-new-order-button {
    margin-bottom: 0px !important;
  }

  .search-section .ui.button {
    margin-top: 0px !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .filter-section, .ag-theme-alpine,  .navbar { 
    width: calc(100% - 16px);
    margin: auto;
  }
  .ag-theme-alpine {
    height: 480px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .filter-section, .ag-theme-alpine,  .navbar { 
    width: 800px;
  }
  .ag-theme-alpine {
    width: 800px;
    height: 480px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .filter-section, .ag-theme-alpine,  .navbar { 
    width: 1180px;
  }
  .ag-theme-alpine {
    width: 1180px
  }
}


.ag-theme-alpine ul {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

.ag-theme-alpine li {
  display: inline-block;
}

.ag-theme-alpine .ui.celled.table,
.ag-theme-alpine.ag-popup {
  position: fixed;
}

.ui.modal {
  background: none !important;
}

body {
  background: #f7f7f7 !important;
}

.navbar .ui.borderless.menu {
  margin-bottom: 0px !important;
}

.navbar .ui.borderless.menu .nav-icon {
  padding-right: 0px;
}

.pagination-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: nowrap;
  background: white;
  border: 1px solid #babfc7;
  border-top: none;
  padding: 0px 15px;
}


.pagination-box span {
  padding: 0px 2px;
}

.pagination {
  margin: 10px 0px !important;
}

.pagination>li>a, .pagination>li>span {
  border: none;
  padding: 5px 10px;
  border: 0px;
  outline: 0px;
}